import { Component, OnInit } from '@angular/core';
import { CacheService } from '../../services/cacheservice.services';
import { DataService } from '../../services/dataservices.service';
import { CuponesService } from '../../services/cupones.services';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-admin-layout',
	templateUrl: './admin-layout.component.html',
	styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

	constructor(private ds: DataService, private cs: CacheService, private router: Router, private http: HttpClient) { }

	async ngOnInit() {
		let token = this.cs.getFromCache('authtoken');
		try {
			const tokenValidationResponse = await this.ds.ValidateToken(token);

			// Checks the Cupones API Key Token is valid...
			const cuponesServices = new CuponesService(this.http, this.cs);
			const cuponesTokenValid = await cuponesServices.IsOAuthValid();
		}
		catch (err) {
			console.log(err);
			this.router.navigate(['/login']);
		}
	}
}
