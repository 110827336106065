import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DecimalPipe } from '@angular/common';
import { Observable, Subject } from "rxjs";
import { map, tap } from 'rxjs/operators';

import { SearchResult } from '../models/searchresult';
import { ISearchResponse } from '../services/searchresult.class';


interface Message {
    channel: string;
    data: any;
}

@Injectable()
export class AdminDataService {
    private message$: Subject<Message>

    // Production:  "https://apimed.aboxplan.com/fet"; 
    // Dev:         "https://apidev.aboxplan.com/fet"; 
    // Local:       "http://localhost:9016";
    baseServerUrl: string = "https://apipub.aboxplan.com/fet";

    constructor(private http: HttpClient) {
    }
    

    CountByCountry(token, requestObj) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/admin/stats/countbycontry';

            // Ejemplo:
            // ---------------------------------
            // let requestObj = {
            //     "searchtext": "FET-", 
            //     "page": 1, 
            //     "pagesize": 1000, 
            //     "sortby": "Nombre", 
            //     "sortdirection": -1
            // }

            try {
                let h = new HttpHeaders().set('Authorization', "Bearer " + token);

                _this.http.post(endpoint, requestObj, { headers:h  }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }

    CountsPerDay(token, requestObj) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/admin/stats/perday';

            // Ejemplo:
            // ---------------------------------
            // let requestObj = {
            //     "searchtext": "FET-", 
            //     "page": 1, 
            //     "pagesize": 1000, 
            //     "sortby": "Nombre", 
            //     "sortdirection": -1
            // }

            try {
                let h = new HttpHeaders().set('Authorization', "Bearer " + token);

                _this.http.post(endpoint, requestObj, { headers:h  }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }

    Top10ProductsByCountry(token, requestObj) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/admin/stats/productsbycountry';

            // Ejemplo:
            // ---------------------------------
            // let requestObj = {
            //     "searchtext": "FET-", 
            //     "page": 1, 
            //     "pagesize": 1000, 
            //     "sortby": "Nombre", 
            //     "sortdirection": -1
            // }

            try {
                let h = new HttpHeaders().set('Authorization', "Bearer " + token);

                _this.http.post(endpoint, requestObj, { headers:h  }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }

    Top10MedicsByCountry(token, requestObj) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/admin/stats/top10medicsbycountry';

            // Ejemplo:
            // ---------------------------------
            // let requestObj = {
            //     "searchtext": "FET-", 
            //     "page": 1, 
            //     "pagesize": 1000, 
            //     "sortby": "Nombre", 
            //     "sortdirection": -1
            // }

            try {
                let h = new HttpHeaders().set('Authorization', "Bearer " + token);

                _this.http.post(endpoint, requestObj, { headers:h  }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch(err) {
                reject(err);
            }
        });
    }
}