import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../../services/messages.services';
import { CacheService } from '../../services/cacheservice.services';

declare interface RouteInfo {
	path: string;
	title: string;
	icon: string;
	class: string;
}
export let ROUTES: RouteInfo[] = [
	{ path: '/newfet', title: 'Crear E-Ticket', icon: '', class: '' },
	{ path: '/dashboard', title: 'Dashboard', icon: '', class: '' },
	{ path: '/createcoupon', title: 'Crear Cupón', icon: '', class: '' }
	// { path: '/admin', title: 'Admin',  icon: '', class: '' }
];

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

	public menuItems: any[];
	public isCollapsed = true;
	public medicName: string = "";

	constructor(private router: Router, private msg: MessageService, private cs: CacheService) {
		this.msg.nameChangeEvent.subscribe((message) => {
			console.log("Name Changed!", message);
			this.medicName = message || "Médico";
		});

		this.msg.toggleMenuEvent.subscribe((message) => {
			this.isCollapsed = message;
		});
	}

	ngOnInit() {
		this.menuItems = ROUTES.filter(menuItem => menuItem);
		this.router.events.subscribe((event) => {
			this.isCollapsed = true;
		});

		// Gets the medics name from the cache:
		this.medicName = this.cs.getFromCache("nombre");
	}

	DoLogout() {
		localStorage.removeItem("medico");
		localStorage.removeItem("authtoken");
		this.router.navigate(['/login']);
	}
}
