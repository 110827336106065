import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CacheService } from '../services/cacheservice.services';
import { DecimalPipe } from '@angular/common';
import { Observable, Subject } from "rxjs";
import { map, tap } from 'rxjs/operators';

import { SearchResult } from '../models/searchresult';
import { ISearchResponse } from './searchresult.class';

interface Message {
    channel: string;
    data: any;
}

@Injectable()
export class CuponesService {
    private message$: Subject<Message>;

    baseServerUrl_Auth: string = "https://apipub.aboxplan.com/cupones_auth";
    baseServerUrl_Medics: string = "https://apipub.aboxplan.com/cupones_medics";
    baseServerUrl_Coupons: string = "https://apipub.aboxplan.com/cupones";

    constructor(private http: HttpClient, private cs: CacheService) {
    }

    async IsOAuthValid() {
        // --------------------------------------------------------
        // 1. Check if token exists on cache
        // 2. If cache is empty, request new token
        // 3. Validate token (cached or fresh)
        // 4. If token is invalid disable coupons functionality
        // --------------------------------------------------------

        let reIssueOAuth: boolean = true;

        const cuponesOAuthToken = this.cs.getFromCache('CuponesOAuthToken');
        if (cuponesOAuthToken && cuponesOAuthToken !== '') {
            const response = await this.ValidateOAuthToken(cuponesOAuthToken);

            if (response === '') {
                reIssueOAuth = true;
            }
            else {
                reIssueOAuth = false;
            }
        }

        if (reIssueOAuth) {
            // Generates a new token from the OAuth endpoint.
            let token = this.cs.getFromCache('authtoken');
            const newCuponesOAuthToken = await this.OAuth(token);
            this.cs.storeCache('CuponesOAuthToken', newCuponesOAuthToken);
        }
    }

    async GetCoupons(params: any) {
        const _this = this;
        return new Promise(async (resolve, reject) => {
            const apiUrl = `${this.baseServerUrl_Medics}/api/v1/medicos/rules/list`;
            const country = this.cs.getFromCache("pais");
            const oauthToken = this.cs.getFromCache("CuponesOAuthToken");
            const medicId = this.cs.getFromCache("medico");

            const { searchText, page, pageSize, sorterColumn } = params;

            const body = {
                "pais": country,
                "id_doctor": medicId,
                "textsearch": searchText || '',
                "paging": {
                    "page": page || 1,
                    "pagesize": pageSize || 10
                },
                "sorter": {
                    "column": sorterColumn || 'PRODUCTO',
                    "direction": 1
                }
            }

            const headers = new HttpHeaders().set('Authorization', oauthToken);
            try {
                _this.http.post(apiUrl, body, { headers }).subscribe(
                    data => {
                        resolve(data?.["response"]?.["details"] || []);
                    },
                    err => {
                        resolve('');
                    }
                );
            }
            catch (err) {
                resolve('');
            }
        });
    }

    private OAuth(token) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl_Auth + '/api/v1/oauth/auth';
            const tokenToSend = `${token}`;
            const body = { 'context': 'Medicos' };

            let h = new HttpHeaders().set('Authorization', tokenToSend);

            try {
                _this.http.post(endpoint, body, { headers: h }).subscribe(
                    data => {
                        resolve(data?.['response']?.['details']?.['token'] || '');
                    },
                    err => {
                        console.log('Cupones OAuth Error:', err);
                        resolve('');
                    }
                );
            }
            catch (err) {
                console.log('Cupones OAuth Catched Error:', err);
                resolve('');
            }
        });
    }

    private ValidateOAuthToken(token) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl_Auth + '/api/v1/oauth/validate-session';
            const tokenToSend = `Bearer ${token}`;

            let h = new HttpHeaders().set('Authorization', tokenToSend);

            try {
                _this.http.post(endpoint, {}, { headers: h }).subscribe(
                    data => {
                        resolve(data?.['response']?.['details']?.['token'] || '');
                    },
                    err => {
                        console.log('Cupones OAuth Error:', err);
                        resolve('');
                    }
                );
            }
            catch (err) {
                console.log('Cupones OAuth Catched Error:', err);
                resolve('');
            }
        });
    }

    async CreateCoupon(couponId) {
        const _this = this;
        return new Promise(async (resolve, reject) => {
            const apiUrl = `${this.baseServerUrl_Coupons}/api/v1/coupon`;
            const oauthToken = this.cs.getFromCache("CuponesOAuthToken");
            const medicId = this.cs.getFromCache("medico");
            const body = {
                idRegla: couponId,
                idDoctor: medicId
            }
            const headers = new HttpHeaders().set('Authorization', oauthToken);
            try {
                _this.http.post(apiUrl, body, { headers }).subscribe(
                    data => {

                        const responseCode = data?.["response"]?.["code"];
                        const dataToReturn = data?.["response"]?.["details"];

                        if (responseCode === 0) {
                            resolve({ data: dataToReturn, message: '' });
                        }
                        else {
                            resolve({ data: dataToReturn, message: data?.["response"]?.["message"] });
                        }
                    },
                    err => {
                        resolve({ data: [], message: '' });
                    }
                );
            }
            catch (err) {
                resolve({ data: [], message: '' });
            }
        });
    }
}
