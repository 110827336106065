import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output, OnInit, ElementRef } from '@angular/core';
import { CacheService } from '../../services/cacheservice.services';
import { DataService } from '../../services/dataservices.service';
import { Observable, fromEvent, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap, finalize, catchError } from 'rxjs/operators';
import { SearchResult } from '../../models/searchresult';
import { MessageService } from '../../services/messages.services';

import { from } from 'rxjs';
import { filter } from 'rxjs/operators'

@Component({
    selector: 'searchbar',
    templateUrl: './searchbar.component.html',
    styleUrls: ['./searchbar.component.scss']
})
export class SearchBarComponent implements OnInit {
    public searchText: any;
    private token: string = "";
    input: ElementRef;
    public _sresults: any = [];

    constructor(private cs: CacheService, private ds: DataService, private ms: MessageService) {
    }

    ClearSearch() {
        this._sresults = [];
        this.searchText = "";
    }

    keyPress($e) {
    }

    prepareSearchResultsForView(results) {
        let f = results.map(r => { 
            r["value"] = r["alf_eticket"] + " - " + r["nombre"] + " " + r["apellido1"]
            return r;
        });
        return f;
    }

    OpenCode(_item) {
        let eticketModalObj = {
            eticket: _item["alf_eticket"],
            producto: _item["producto"],
            patientName: _item["nombre"] + " " + _item["apellido1"] + " " + _item["apellido2"],
            correo: _item["correo"],
            hasEmail: _item["correo"] === "" ? false : true
        }

        this.ms.OpenFetModal(eticketModalObj);
        this.ClearSearch();
    }

    ngOnInit() {
        this.token = this.cs.getFromCache("authtoken");
    }

    ngAfterViewInit() {
        fromEvent<any>(document.getElementById("searcht"), 'keyup')
            .pipe(
                map(event => event.target.value),
                debounceTime(400),
                // distinctUntilChanged(),
                switchMap(partialSearch => 
                    this.ds.SearchByText(
                        this.token, {
                            "searchtext": partialSearch, 
                            "page": 1, 
                            "pagesize": 20, 
                            "sortby": "Nombre", 
                            "sortdirection": -1
                        })
                        .pipe(
                            map(results => this.prepareSearchResultsForView(results["results"]))
                        )
                )
            )
            .subscribe(
                (sresults) => {
                    this._sresults = [];
                    this._sresults = sresults;
                },
                (error) => console.log(error),
                () => console.log("Complete.")
            );
    }

    ngOnChanges(changes: SimpleChanges) {
        
    }
}