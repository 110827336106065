import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchComponent } from './search/search.component';
import { FetModalComponent } from './fetmodal/fetmodal.component';
import { SimpleSearchComponent } from './simplesearch/simplesearch.component';
import { SearchBarComponent } from './searchbar/searchbar.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    SearchComponent,
    FetModalComponent,
    SimpleSearchComponent,
    SearchBarComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    SearchComponent,
    FetModalComponent,
    SimpleSearchComponent,
    SearchBarComponent
  ]
})
export class ComponentsModule { }
