import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ChartsModule } from 'ng2-charts';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { DataService } from './services/dataservices.service';
import { CacheService } from './services/cacheservice.services';
import { MessageService } from './services/messages.services';
import { AdminDataService } from './services/admindataservice.service';

import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { LocationStrategy, PathLocationStrategy, HashLocationStrategy } from '@angular/common';

import { CuponesService } from './services/cupones.services';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ChartsModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent
  ],
  exports: [
  ],
  providers: [DataService, CuponesService, CacheService, MessageService, AdminDataService, { provide: LocationStrategy, useClass: PathLocationStrategy }, { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule { }
