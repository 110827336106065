import { Component, OnInit } from '@angular/core';
import { CacheService } from '../../services/cacheservice.services';
import { DataService } from '../../services/dataservices.service';
import Swal from 'sweetalert2';
import { EventEmitter } from "@angular/core";
import domtoimage from 'dom-to-image';
import { MessageService }  from '../../services/messages.services';
import { NgNavigatorShareService } from 'ng-navigator-share';

@Component({
    selector: 'fet-modal',
    templateUrl: './fetmodal.component.html',
    styleUrls: ['./fetmodal.component.scss']
})
export class FetModalComponent implements OnInit {

    private token: string = "";

    // Event Emitters that communicate information to listeners
    triggerEvent = new EventEmitter();

    public _eticket: string = "";
    public _producto: string = "";
    public _patientName: string = "";
    public _email: string = "";
    public _hasEmail: boolean = false;
    public _isMobile: boolean = false;

    public _processing: boolean = false;
    public _processingMessage: string = "";

    constructor(private cs: CacheService, private ds: DataService, private ms: MessageService, public ngNavigatorShareService: NgNavigatorShareService) {
        this.ms.openFetModalEvent.subscribe((eticketModalObject) => {

            // eticket: "FET-5ARKC"
            // producto: "ENSURE POLVO VAINILLA 400 g"
            // patientName: "Jose Perez "
            // correo: "chepe@loymark.com"
            // hasEmail: true

            this._eticket = eticketModalObject["eticket"];
            this._producto = eticketModalObject["producto"];
            this._patientName = eticketModalObject["patientName"];
            this._email = eticketModalObject["correo"];
            this._hasEmail = this._email != '' ? true : false;

            this.OpenModal();
        });
    }

    // Other Helpers
    SaveToImageDOMElement() {
        let node = document.getElementById("m-body");
        domtoimage.toJpeg(node, { quality: 1, bgcolor: "#ffffff" })
            .then(async function (dataUrl) {
                let img: HTMLImageElement = document.getElementById("resultImg") as HTMLImageElement;
                img.src = dataUrl;

                console.log(img.src);
            });
    }

    SaveAsImage() {
        let node = document.getElementById("m-body");
        this._processing = true;
        let _this = this;
        domtoimage.toJpeg(node, { quality: 1, bgcolor: "#ffffff" })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'Aboxplan-Eticket.jpeg';
                link.href = dataUrl;
                link.click();
                _this._processing = false;
            });
    }

    CloseOverlay() {
        document.getElementById("overlay").style.display = "none";
        document.getElementById("modal-container").style.display = "none";
    }

    async Share() {
        this._processing = true;

        try {
            await this.ngNavigatorShareService.share({
                title: "Aboxplan E-ticket",
                text: this._eticket,
                url: ""
            });

            this._processing = false;
        }
        catch(err) {
            this._processing = false;
            // alert("Error " + JSON.stringify(err));
            console.log(err);
        }
    }

    async ResendCode() {
        if (this._hasEmail) {
            try {
                this._processing = true;
                this._processingMessage = "";
                let r = await this.ds.ResendbyEmail(this.token, this._eticket);
                this._processing = false;
                this._processingMessage = "Listo. Se reenvió el correo."
            }
            catch(err) {
                console.log(err);
                this._processing = false;
                this._processingMessage = "";
            }
        }
    }

    OpenModal() {
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            this._isMobile = true;
        }

        this._processing = false;
        this._processingMessage = "";

        document.getElementById("overlay").style.display = "block";
        document.getElementById("modal-container").style.display = "block";
    }

    ngOnInit() {
        this.token = this.cs.getFromCache("authtoken");
    }
}