import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DecimalPipe } from '@angular/common';
import { Observable, Subject } from "rxjs";
import { EventEmitter } from "@angular/core";

interface Message {
    channel: string;
    data: any;
}

@Injectable()
export class MessageService {
    private message$: Subject<Message>;

    // Event Emitters that communicate information to listeners
    nameChangeEvent = new EventEmitter();
    openFetModalEvent = new EventEmitter();
    toggleMenuEvent = new EventEmitter();
    resendEticketEmailEvent = new EventEmitter();
    clearProductSearchEvent = new EventEmitter();
    openCouponModalEvent = new EventEmitter();
    reloadCouponsEvent = new EventEmitter();
    searchBoxActionEvent = new EventEmitter();

    NameChanged(newName: string) {
        this.nameChangeEvent.emit(newName);
    }

    OpenFetModal(obj) {
        this.openFetModalEvent.emit(obj);
    }

    CloseSideBarMenu() {
        this.toggleMenuEvent.emit(true);
    }

    ResendEticketEmail() {
        this.resendEticketEmailEvent.emit();
    }

    ClearProductSearch() {
        this.clearProductSearchEvent.emit();
    }

    OpenCouponModal(obj) {
        this.openCouponModalEvent.emit(obj);
    }

    ReloadCoupons(obj) {
        this.reloadCouponsEvent.emit(obj);
    }

    SearchBoxAction(obj) {
        this.searchBoxActionEvent.emit(obj);
    }
}