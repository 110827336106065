

export class SearchResult {
    id: string;
    nombre: string;
    apellido1: string;
    apellido2: string;
    correo: string;
    telefono: string;
    nombre_medico: string;
    apellido1_medico: string;
    apellido2_medico: string;
    id_eticket: string;
    alf_eticket: string;
    activo: string;
    pais: string;
    producto: string;
    descripcion: string;
    createdate: string;
    updatedate: string;

    constructor(
        _id,
        _nombre,
        _apellido1,
        _apellido2,
        _correo,
        _telefono,
        _nombre_medico,
        _apellido1_medico,
        _apellido2_medico,
        _id_eticket,
        _alf_eticket,
        _activo,
        _pais,
        _producto,
        _descripcion,
        _createdate,
        _updatedate) {
        this.id = _id;
        this.nombre = _nombre;
        this.apellido1 = _apellido1;
        this.apellido2 = _apellido2;
        this.correo = _correo;
        this.telefono = _telefono;
        this.nombre_medico = _nombre_medico;
        this.apellido1_medico = _apellido1_medico;
        this.apellido2_medico = _apellido2_medico;
        this.id_eticket = _id_eticket;
        this.alf_eticket = _alf_eticket;
        this.activo = _activo;
        this.pais = _pais;
        this.producto = _producto;
        this.descripcion = _descripcion;
        this.createdate = _createdate;
        this.updatedate = _updatedate;
    }
}