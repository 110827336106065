import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { DecimalPipe } from '@angular/common';
import { Observable, Subject } from "rxjs";
import { map, tap } from 'rxjs/operators';

import { SearchResult } from '../models/searchresult';
import { ISearchResponse } from '../services/searchresult.class';

interface Message {
    channel: string;
    data: any;
}

@Injectable()
export class DataService {
    private message$: Subject<Message>

    // Production:  "https://apimed.aboxplan.com/fet"; 
    // Dev:         "https://apidev.aboxplan.com/fet"; 
    // Local:       "http://localhost:9016";
    baseServerUrl: string = "https://apimed.aboxplan.com/fet";

    constructor(private http: HttpClient) {
    }

    ValidateToken(token) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/validatetoken';

            let h = new HttpHeaders().set('Authorization', "Bearer " + token);

            try {
                _this.http.post(endpoint, {}, { headers: h }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch (err) {
                reject(err);
            }
        });
    }

    EvaluteRespose(response) {
        if (response["header"]["code"] === 0) {
            return response["response"];
        }
        else return null;
    }

    DoLogin(user, password) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/login';

            _this.http.post(endpoint, { user: user, password: password }, {}).subscribe(
                data => {
                    let r = _this.EvaluteRespose(data);
                    if (r === null) {
                        reject("Ocurrió un problema.");
                    }
                    else {

                        // OAuth Services
                        const token = r["token"];
                        resolve({ token: token, medico: r["medico"], nombre: r["nombre"] || 'Medico', pais: r["pais"] });
                    }
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    CreateFET(token, requestObj) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/create';

            // Ejemplo:
            // ---------------------------------
            // let reqobj = {
            //     "medico": "1",
            //     "producto": "100P073K02E89",
            //     "nombre": "Prueba",
            //     "apellido1": "Prueba",
            //     "apellido2": "",
            //     "correo":"loymark@loymark.com"
            // }  

            try {
                let h = new HttpHeaders().set('Authorization', "Bearer " + token);

                _this.http.post(endpoint, requestObj, { headers: h }).subscribe(
                    data => {

                        let r = _this.EvaluteRespose(data);
                        if (r === null) {
                            reject("Ocurrió un problema.");
                        }
                        else {
                            // Evalutes the response. 

                            if (r["code"] !== 0) {
                                if (r["code"] === "FETERR-0024") {
                                    let e = { code: "FETERR-0024", details: "Alcanzó su límite de creación de E-Tickets para ese producto." };
                                    reject(e);
                                    return;
                                }

                                reject(r["message"]);
                                return;
                            }

                            resolve(r);
                        }
                    },
                    err => {
                        if (err["status"] === 422) {
                            let e = { code: 422, details: err["error"]["response"]["details"] };
                            reject(e);
                            return;
                        }
                        let e = { code: err["status"], details: err };
                        reject(err);
                    }
                );
            }
            catch (err) {
                reject(err);
            }
        });
    }

    GetMostRecentFets(token, medico) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/searchbymedic';

            let h = new HttpHeaders().set('Authorization', "Bearer " + token)

            let d = new Date();
            let m = String(d.getMonth() + 1);
            let ds = d.getFullYear() + "-" + m.padStart(2, '0') + "-" + d.getDate()

            _this.http.post(endpoint, {
                "medico": medico,
                "searchtext": "",
                "startdate": "2020-03-16", // Start date when the tool was released
                "enddate": ds,
                "page": 1,
                "pagesize": 10,
                "sortby": "fecha",
                "sortdirection": -1
            }, { headers: h }).subscribe(
                data => {
                    let r = _this.EvaluteRespose(data);
                    if (r === null) {
                        reject("Ocurrió un problema.");
                    }
                    else {
                        resolve(r);
                    }
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    GetProductsByMedic(token, medico) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/productsbymedic';

            let h = new HttpHeaders().set('Authorization', "Bearer " + token)

            _this.http.post(endpoint, {
                "medico": medico,
                "page": 1,
                "pagesize": 1000,
                "sortby": "nombre",
                "sortdirection": 1
            }, { headers: h }).subscribe(
                data => {
                    let r = _this.EvaluteRespose(data);
                    if (r === null) {
                        reject("Ocurrió un problema.");
                    }
                    else {
                        resolve(r);
                    }
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    ResendbyEmail(token, eticket) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/resendbymail';

            let h = new HttpHeaders().set('Authorization', "Bearer " + token)

            _this.http.post(endpoint, {
                "eticket": eticket
            }, { headers: h }).subscribe(
                data => {
                    resolve(data)
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    VerifyTNC(token, medico) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/verifytnc';
            let h = new HttpHeaders().set('Authorization', "Bearer " + token);

            _this.http.post(endpoint, { medico: medico }, { headers: h }).subscribe(
                data => {
                    resolve(data);
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    CreateTNC(token, requestObj) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/createtnc';

            // Ejemplo:
            // ---------------------------------
            // let reqobj = {
            //     "medico": 1,
            //     "suscripcion": 1 / 0
            // }  

            try {
                let h = new HttpHeaders().set('Authorization', "Bearer " + token);

                _this.http.post(endpoint, requestObj, { headers: h }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        if (err["status"] === 422) {
                            let e = { code: 422, details: err["error"]["response"]["details"] };
                            reject(e);
                            return;
                        }
                        let e = { code: err["status"], details: err };
                        reject(err);
                    }
                );
            }
            catch (err) {
                reject(err);
            }
        });
    }

    Search(token, requestObj) {
        let _this = this;
        return new Promise(async (resolve, reject) => {
            const endpoint = _this.baseServerUrl + '/search';

            // Ejemplo:
            // ---------------------------------
            // let requestObj = {
            //     "searchtext": "FET-", 
            //     "page": 1, 
            //     "pagesize": 1000, 
            //     "sortby": "Nombre", 
            //     "sortdirection": -1
            // }

            try {
                let h = new HttpHeaders().set('Authorization', "Bearer " + token);

                _this.http.post(endpoint, requestObj, { headers: h }).subscribe(
                    data => {
                        resolve(data);
                    },
                    err => {
                        reject(err);
                    }
                );
            }
            catch (err) {
                reject(err);
            }
        });
    }

    SearchByText(token, requestObj): Observable<ISearchResponse> {
        // Ejemplo:
        // ---------------------------------
        // let requestObj = {
        //     "searchtext": "FET-", 
        //     "page": 1, 
        //     "pagesize": 1000, 
        //     "sortby": "Nombre", 
        //     "sortdirection": -1
        // }

        // Response Sample:
        // ---------------------------------
        // idFETRMExM: 109
        // Nombre: "vinicio"
        // Apellido1: "corona"
        // Apellido2: ""
        // Correo: "coronavinicio@yahoo.com"
        // Telefono: "47682102"
        // Nombre_Medico: "CORONA AVALOS VINICIO ESTUARDO"
        // Apellido1_Medico: ""
        // Apellido2_Medico: ""
        // ID_Eticket: 1137195
        // Alf_eticket: "FET-LCLPG"
        // Activo: "1"
        // Pais: "GT"
        // Producto: "CRESADEX EZE 20/10"
        // Descripcion: "ROSUVASTATINA 20MG+EZETIMIBE 10MG 28TAB"
        // createDate: "2020-04-03T03:13:20.000Z"
        // updateDate: null        

        const endpoint = this.baseServerUrl + '/search';
        let h = new HttpHeaders().set('Authorization', "Bearer " + token);
        return this.http.post<ISearchResponse>(endpoint, requestObj, { headers: h })
            .pipe(
                tap((response: ISearchResponse) => {
                    let resultItems = response["response"]["details"]["data"];
                    response.results = resultItems.map(result => new SearchResult(
                        result.idFETRMExM,
                        result.Nombre,
                        result.Apellido1,
                        result.Apellido2,
                        result.Correo,
                        result.Telefono,
                        result.Nombre_Medico,
                        result.Apellido1_Medico,
                        result.Apellido2_Medico,
                        result.ID_Eticket,
                        result.Alf_eticket,
                        result.Activo,
                        result.Pais,
                        result.Producto,
                        result.Descripcion,
                        result.createDate,
                        result.updateDate
                    ))
                })
            );
    }
}
