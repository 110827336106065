import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output, OnInit } from '@angular/core';
import { CacheService } from '../../services/cacheservice.services';
import { DataService } from '../../services/dataservices.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap, finalize, catchError } from 'rxjs/operators';
import { SearchResult } from '../../models/searchresult';
import { MessageService } from '../../services/messages.services';

@Component({
    selector: 'simple-search-fet',
    templateUrl: './simplesearch.component.html',
    styleUrls: ['./simplesearch.component.scss']
})
export class SimpleSearchComponent implements OnInit {
    public searchText: any;
    
    private token: string = "";
    private resultsList: any = [];
    public isLoading: boolean = false;
    clickedItem:string;

    @Output() selectedItemChanged = new EventEmitter();

    formatter = (r: SearchResult) => {
        if (r) {
            return r["alf_eticket"] + " - " + r["nombre"] + " " + r["apellido1"] + " " + r["apellido2"];
        }
        return "";
    }

    constructor(private cs: CacheService, private ds: DataService, private ms: MessageService) {
        this.ms.openFetModalEvent.subscribe((message) => {
            this.searchText = "";
        });
    }

    selectedItem($event){
        $event.preventDefault();
		let _item = $event.item;

        let eticketModalObj = {
            eticket: _item["alf_eticket"],
            producto: _item["producto"],
            patientName: _item["nombre"] + " " + _item["apellido1"] + " " + _item["apellido2"],
            correo: _item["correo"],
            hasEmail: _item["correo"] === "" ? false : true
        }

        this.ms.OpenFetModal(eticketModalObj);

        this.searchText = null; // Clears the searchbox

        this.ms.CloseSideBarMenu();
        
	}
    
    prepareSearchResultsForView(results) {
        return results.filter(r => {
            return r["alf_eticket"] + " - " + r["nombre"] + " " + r["apellido1"] + " " + r["apellido2"];
        }).slice(0, 20);;
    }

    search = (text$: Observable<string>) => {
        return text$.pipe(
            debounceTime(400),
            distinctUntilChanged(),
            switchMap(partialSearch => this.ds.SearchByText(
                this.token, {
                    "searchtext": this.searchText, 
                    "page": 1, 
                    "pagesize": 20, 
                    "sortby": "Nombre", 
                    "sortdirection": -1
                })
                .pipe(
                    map(results => this.prepareSearchResultsForView(results["results"]))
                )
            )
        );
    }

    ngOnInit() {
        this.token = this.cs.getFromCache("authtoken");
    }
}